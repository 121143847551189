import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.changedElements = new Set()
        this.handleFrameLoaded = this.onFrameLoad.bind(this)

        this.attachChangeListeners()
        document.addEventListener('turbo:frame-load', this.handleFrameLoaded)
    }

    disconnect() {
        document.removeEventListener('turbo:frame-load', this.handleFrameLoaded)
    }

    onFrameLoad(event) {
        if (this.element.contains(event.target)) {
            this.clearChangedElementsThatNoLongerExist()
            this.attachChangeListeners()
        }
    }

    clearChangedElementsThatNoLongerExist() {
        this.changedElements.forEach(element => {
            if (!this.element.contains(element)) {
                this.changedElements.delete(element)
            }
        })
    }

    attachChangeListeners() {
        this.element.querySelectorAll('input, textarea, select').forEach(element => {
            element.addEventListener('change', this.flagChange.bind(this))
            element.addEventListener('input', this.flagChange.bind(this))
        })
    }

    flagChange(event) {
        this.changedElements.add(event.target)
    }

    get hasUnsavedChanges() {
       return this.changedElements.size > 0
    }

    checkUnsavedChanges(event) {
        if (this.hasUnsavedChanges) {
            if (!confirm("Are you sure you want to leave this page? Changes you made may not be saved.")) {
                event.preventDefault()
            }
        }
    }
}
