import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    "tipContainerId": String
  }
  
  toggleTip(event) {
    const tipContainer = document.getElementById(this.tipContainerIdValue);
    if(tipContainer) {
      tipContainer.classList.toggle("hidden");
    }

  }
}
