import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delayMS: { type: Number, default: 2000 },
  }
  static targets = [
    "click"
  ]

  onTimeout() {
    if (this.hasClickTarget) {
      this.clickTarget.click()
      return
    }
    
    // click the first button or link in it's children
    const firstChild = this.element.querySelector("a, button")
    if (firstChild) {
      firstChild.click()
      return
    }

    // if no target or children, click the element itself

    this.element.click()
  }
  
  connect() {
    if (this.delayMSValue) {
      if (this.timerId) { clearTimeout(this.timerId) }
      this.timerId = setTimeout(this.onTimeout(), this.delayMSValue)
    }
  }

  disconnect() {
    if (this.timerId) { clearTimeout(this.timerId) }
  }
}

