import FormStateController from "./form_state_controller"

/*
Attach this to a form or an element containing a form
when the form is submitted it will hide the element
*/
export default class extends FormStateController {
    setupForm(form) {
            form.addEventListener("submit", this.handleSubmit.bind(this))
    }

    handleSubmit() {
        this.element.classList.add('hidden');
        // don't restore the state here - as it will then remove all the users changes
        // focus on the parent element
        if (this.element.parentElement) {
            this.element.parentElement.focus();
        }
    }
}
