import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "saveButton" ]

  connect() {
    this.inputTarget.addEventListener("input", this.onInputChanged.bind(this))
    this.inputTarget.addEventListener("focus", this.onFocus.bind(this))
    this.inputTarget.addEventListener("blur", this.onBlur.bind(this))
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.onInputChanged)
    this.inputTarget.removeEventListener("focus", this.onFocus)
    this.inputTarget.removeEventListener("blur", this.onBlur)
  }

  onInputChanged(event) {
    this.showSave();
  }

  onFocus(event) {
  }

  onBlur(event) {
  }

  showSave() {
    this.saveButtonTarget.classList.remove("hidden")
  }
}
