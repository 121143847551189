import FormStateController from "./form_state_controller"

/*
Attach this to a form or an element containing a form
if there is a cancel button within the form it will bind that
and hide the whole element if cancel is clicked

It finds the cancel button in the following order:
- cancelValue: the id of the cancel button
- a form button with data-action="cancel"
- a form element with id "cancel"
- a form element with class "cancel"
- a link or button with the text "cancel"
 */
export default class extends FormStateController {
    static values = {
        "cancel": String
    }

    setupForm(form) {
        const cancelButton = this.cancelButton();
        if (cancelButton) {
            cancelButton.addEventListener("click", this.cancel.bind(this));
        } else {
            console.warn("Cancel button not found");
        }
    }

    cancel(event) {
        event.preventDefault();
        this.restoreState();
        this.element.classList.add('hidden');
        // focus on the parent element
        if (this.element.parentElement) {
            this.element.parentElement.focus();
        }
    }

    cancelButton() {
        if (this.cancelValue) {
            return document.getElementById(this.cancelValue);
        }
        const cancelButton = this.element.querySelector('[type="button"][data-action="cancel"]');
        if (cancelButton) {
            return cancelButton;
        }

        const cancel = this.element.querySelector('#cancel');
        if (cancel) {
            return cancel;
        }
        const cancelClass = this.element.querySelector('.cancel');
        if (cancelClass) {
            return cancelClass;
        }
        const cancelText = Array.from(this.element.querySelectorAll('button, a'))
            .find(element => element.textContent.toLowerCase() === 'cancel');
        if (cancelText) {
            return cancelText;
        }
        return null
    }
}
