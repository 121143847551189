import { Controller } from "@hotwired/stimulus"

// shows an image in a div

// example
// <div class="bg-white flex flex-col items-center justify-center h-screen" data-controller="show-image">
// <!-- the show image div -->
// <div id="show-image" class="hidden fixed top-0 right-0 w-full h-full flex items-center justify-center z-50" data-action="click->show-image#hide">
// <div class="absolute top-0 right-0 p-4">
//   <button id="show-image-close" class="text-gray-600 text-2xl">X</button>
// </div>
// <img id="show-image-image" src="../../assets/images/timeblock1.png" alt="Image" class="m-8 max-w-full h-auto">
// </div>
// <!-- links to click on to show the image
// <ul class="list-none">
// <li>1. Simple (I started with this one on my non-clinic days).
//   <a data-src="<%= asset_path('timeblock/timeblock1.png') %>" data-action="click->show-image#show"
//     class="text-blue-600 underline">Example 1</a>
// </li>

export default class extends Controller {
    static targets = [
        "container" // the container to show the image in otherwise use id 'show-image'
    ]

    connect() {
        if (this.hasContainerTarget) {
            this.container = this.containerTarget
        } else {
            this.container = document.getElementById("show-image")
            if (!this.container) {
                console.error("ShowImage: missing image container")
                return
            }
        }

        this.image = this.container.querySelector("img")
        if (!this.image) {
            console.error("ShowImage: missing image")
            return
        }
    }

    show(event) {
        if (!this.container || !this.image) {
            console.error("ShowImage: missing container or image")
            return
        }

        if (!event.target) {
            console.error("ShowImage: missing event target")
            return
        }

        const url = event.target.getAttribute("data-src")
        if (!url) {
            console.error("ShowImage: missing image url")
            return
        }

        this.image.setAttribute("src", url)
        this.container.classList.remove("hidden")
        event.preventDefault()
    }

    hide(event) {
        if (!this.container || !this.image) {
            console.error("ShowImage: missing container or image")
            return
        }
        this.container.classList.add("hidden")
        event.preventDefault()
    }
}