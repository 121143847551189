import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "showMoreInTimeBoxView"
  ]

  toggle() {
    var hasChanged = false
    if (this.hasShowMoreInTimeBoxViewTarget) {
      this.showMoreInTimeBoxViewTarget.checked = !this.showMoreInTimeBoxViewTarget.checked
      hasChanged = true
    }

    if (hasChanged) {
      this.showMoreInTimeBoxViewTarget.form.requestSubmit()
    }
  }

}
