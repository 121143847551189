import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.boundHandleKeyDown = this.handleKeyDown.bind(this);
    document.addEventListener("keydown", this.boundHandleKeyDown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.boundHandleKeyDown);
  }

  handleKeyDown(event) {
    var focusedElement = document.activeElement;
    var isTaskSelected = focusedElement && focusedElement.hasAttribute("data-task-index");

    if (isTaskSelected) {
      var focusedTaskIndex = parseInt(focusedElement.getAttribute("data-task-index"), 10);

      switch(event.key) {
        case "ArrowUp":
        case "k":
          const prevTaskIndex = focusedTaskIndex - 1;
          var prevTask = document.getElementById("task-" + prevTaskIndex);
          if (prevTask) prevTask.focus();
          event.preventDefault();
          event.stopPropagation();
          break;
        case "ArrowDown":
        case "j":
          const nextTaskIndex = focusedTaskIndex + 1; 
          var nextTask = document.getElementById("task-" + nextTaskIndex);
          if (nextTask) nextTask.focus();
          event.preventDefault();
          event.stopPropagation();
          break;
        case " ":
          // Assuming there's a function to complete a task
          this.completeTask(focusedTaskIndex);
          event.preventDefault();
          event.stopPropagation();
          break;
        case "Enter":
          // Assuming there's a function to start a task
          this.startTask(focusedTaskIndex);
          event.preventDefault();
          event.stopPropagation();
          break;
        case "e":
        case "E":
        case ":":
          // Assuming there's a function to edit a task
          this.editTask(focusedTaskIndex);
          event.preventDefault();
          event.stopPropagation();
          break;
        default:
          break;
      }
    }
  }

  completeTask(index) {
    // find the form with id complete-task-index-#{index}, check the done checkbox within it and submit the form
    var completeTaskForm = document.getElementById("complete-task-index-" + index);
    var doneCheckbox = completeTaskForm.querySelector("input[type=checkbox]");
    doneCheckbox.click()
    // doneCheckbox.checked = true;
  }

  startTask(index) {
    // find link with start-task-index-#{index} and click it
    var startTaskLink = document.getElementById("start-task-index-" + index);
    startTaskLink.click();
  }

  editTask(index) {
    // find link edit-button and click it
    var editButton = document.getElementById("edit-button");
    editButton.click();
  }
}
