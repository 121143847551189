import { Controller } from '@hotwired/stimulus'
import { Accordion } from 'flowbite'

const OPEN_ACCORDION_IDS_KEY = 'plan-open-accordion-ids';

export default class extends Controller {

  static values = {
    accordionIdPrefix: String,
    debug: {
      type: Boolean,
      default: false
    },
  }

  updateOpenAccordionIds(ids) {
    if(ids.length) {
      localStorage.setItem(OPEN_ACCORDION_IDS_KEY, ids.join(','))
    }
    else {
      localStorage.removeItem(OPEN_ACCORDION_IDS_KEY);
    }
  }

  getOpenAccordionIds() {
    const storedIds = localStorage.getItem(OPEN_ACCORDION_IDS_KEY);
    if (storedIds !== null) {
      return storedIds.split(',')
    }
    return null
  }

  isOpenInitially(element, index) {
    const openAccordionIds = this.getOpenAccordionIds();

    // Always open if contains currently active step
    if (element.hasAttribute('data-plan-accordion-default-active')) {
      return true;
    }

    if (openAccordionIds !== null) {
      // We have LocalStorage, use that
      return openAccordionIds.some( (id) => {
        return id === element.id
      });
    }
    
    // If that fails, open the first one
    return index == 0;
  }

  connect() {
    this.log('connect', this.element)

    // Get accordion elements from DOM
    const accordionElements = this.element.querySelectorAll(`[id^="${this.accordionIdPrefixValue}-item-"`)
   
    // Init Accordion object
    const accordionItems = [];
    accordionElements.forEach( (element, index) => {
      const triggerEl = element.querySelector('[data-plan-accordion-trigger]');
      const iconEl = triggerEl.querySelector('[data-accordion-icon]');
      accordionItems.push({
        id: element.id,
        triggerEl,
        iconEl,
        targetEl: element.querySelector('[data-plan-accordion-target]'),
        active: this.isOpenInitially(element, index)
      })
    } );
    const accordionOptions = {
        alwaysOpen: true,
        activeClasses: 'bg-gray-100 text-gray-900',
        inactiveClasses: 'text-gray-500 dark:text-gray-400',
        onOpen: (e) => {},
        onClose: (e) => {},
        onToggle: (e) => {
          this.log('onToggle', e);
          const activeItems = this.__accordion._items.map( (item) => {
            if(item.active) {
              return item.id
            }
          });
          this.updateOpenAccordionIds(activeItems);
        },
    };
    this.__accordion = new Accordion(this.element, accordionItems, accordionOptions);
    this.log('initialized', {
      accordion: this.__accordion,
      accordionOptions,
      accordionElements,
      accordionItems
    })
  }

  log(message, obj) {
    if (this.debugValue) {
      if(obj) {
        return console.log(`[Plan] ${message}`, obj)
      }
      console.log(`[Plan] ${message}`)
    }
  }
}
