import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        confettiContainer: { type: String, default: "confetti-container" },
        now: { type: Boolean, default: false }
    }

    connect() {
        this.confettiCounter = 0;
        this.confettiTimer = null;

        if (this.nowValue) {
            this.celebrate();
        }
    }

    celebrate() {
        const confettiContainer = document.getElementById(this.confettiContainerValue);
        if (!confettiContainer) {
            console.error("Confetti container not found");
            return;
        }

        this.confettiCounter++;

        confettiContainer.classList.remove('hidden');

        if (!this.confettiTimer) {
            this.confettiTimer = setInterval(() => {
                this.confettiCounter--;
                if (this.confettiCounter === 0) {
                    confettiContainer.classList.add('hidden');
                    clearInterval(this.confettiTimer);
                    this.confettiTimer = null;
                }
            }, 5000);
        }
    }
}
