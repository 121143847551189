import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    toggleMenuInteractivity(event) {
        if(event && event.detail) {
            const isVisible = event.detail.isToggled;

            const links = this.element.querySelectorAll('.electron-menu-link');
            links.forEach(link => {
                if (isVisible) {
                    // Enable links and tabbing when menu is visible
                    link.removeAttribute('tabindex');
                    link.style.pointerEvents = '';
                } else {
                    // Disable links and tabbing when menu is not visible
                    link.setAttribute('tabindex', '-1');
                    link.style.pointerEvents = 'none';
                }
            });
        }
    }

}