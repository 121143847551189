import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        "userName": String,
        "userEmail": String,
        "userId": String,
        "utm": String
    }

    isCalendlyEvent(e) {
        return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
    }

    initEmbed() {
        Calendly.initInlineWidget({
            parentElement: document.getElementById('calendly-modal-body'),
            url: 'https://calendly.com/d/g32-kvv-kxq/flow-session?hide_event_type_details=1&hide_gdpr_banner=1',
            prefill: {
                name: this.userNameValue,
                email: this.userEmailValue
            },
            utm: {
                utmCampaign: this.utmValue,
                utmSource: this.userIdValue
            }
        });
    }

    connect() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute(
            'src',
            'https://assets.calendly.com/assets/external/widget.js'
        );
        const self = this;
        script.onload = () => {
            window.addEventListener("message", function(e) {
                if(self.isCalendlyEvent(e)) {
    
                    if (e.data.event === 'calendly.event_scheduled' ) {
                        self.bookingCompleted(e.data.payload);
                    }
    
                    /* Example to get the name of the event */
                    // console.log("Calendly Event data:", e.data);
    
                    /* Example to get the name of the event */
                    // console.log("Calendly Event name:", e.data.event);
    
                    /* Example to get the payload of the event */
                    // console.log("Calendly Event details:", e.data.payload);
                }
            });
            self.initEmbed();
        };
        head.appendChild(script);
    }
  
    bookingCompleted(bookingData) {
        // trigger a UI update instead
        window.setTimeout(()=> {
            // Reload page
            location.reload();
        }, 1000);
        
    }

}
