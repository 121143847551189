// graph_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "circle" ]

  static values = {
    circleSize: { type: Number, default: 50 },
    msPassed: Number,
    durationSec: Number
  }

  connect() {
    this.update();
  }

  calculateStrokeDashOffset(percentagePassed) {
    const fullCircumference = 2 * Math.PI * this.circleSizeValue;
    const partialCircumference = 0.75 * fullCircumference;
    // Interpolate between the full and partial circumference based on the progress
    const strokeDashOffset = partialCircumference - (percentagePassed / 100) * partialCircumference;
    return strokeDashOffset;
}

  update() {
    let percentagePassed = this.msPassedValue / (this.durationSecValue * 1000) * 100;
    percentagePassed = (percentagePassed > 100) ? 100 : percentagePassed;

    if(this.hasCircleTarget) {
      this.circleTarget.style = "--circle-rotation: " + this.calculateStrokeDashOffset(percentagePassed) + ";";
    }
  }

}