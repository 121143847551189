import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    tipContainerId: String,
    timerViewSelector: String
  }

  toggleTip(event) {
    const tipContainer = document.getElementById(this.tipContainerIdValue);
    if(tipContainer) {
      tipContainer.classList.toggle("hidden");
    }
  }

  priorityChanged(event) {
    if (event && event.target) {
      const timerView = document.querySelector(this.timerViewSelectorValue);
      if(timerView) {
        timerView.setAttribute("data-priority", event.target.value);
      }
    }
  }
}
