import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["task", "area", "project"]

    static values = {
        delayMillis: { type: Number, default: 5000 }
    }
    connect() {
        this.cleanUp()
        this.createContainer()
        setTimeout(() => this.launchBalloons(), this.delayMillisValue)
        this.activeDrag = null
    }

    createContainer() {
        this.container = document.createElement("div")
        // Remove z-index and add pointer-events-none to allow clicking through
        // Only enable pointer events on the balloons themselves
        this.container.className = "balloon-container fixed inset-0 overflow-hidden pointer-events-none"
        document.body.appendChild(this.container)
    }

    launchBalloons() {
        const allTargets = [
            ...this.taskTargets.map(t => ({ element: t, type: 'task', color: 'bg-blue-500' })),
            ...this.areaTargets.map(t => ({ element: t, type: 'area', color: 'bg-green-500' })),
            ...this.projectTargets.map(t => ({ element: t, type: 'project', color: 'bg-purple-500' }))
        ]

        allTargets.forEach((target) => {
            const delay = Math.random() * 2000 + 500
            setTimeout(() => {
                const title = target.element.dataset.title || target.type
                const minutes = parseInt(target.element.dataset.minutes) || 30
                this.createBalloon(title, minutes, target.color)
            }, delay)
        })
    }

    createBalloon(title, minutes, colorClass) {
        const size = Math.min(Math.max(minutes * 2, 40), 120)
        const balloon = document.createElement("div")

        const startX = Math.random() * (window.innerWidth - size)
        const startY = window.innerHeight + size
        const finalX = window.innerWidth - size - (Math.random() * 150) - 50
        const finalY = Math.random() * 150 + 50

        balloon.className = `
            balloon-instance absolute flex items-center justify-center
            text-center text-white font-medium
            rounded-full shadow-lg opacity-80 cursor-grab
            pointer-events-auto
            ${colorClass}
        `

        balloon.style.width = `${size}px`
        balloon.style.height = `${size}px`
        balloon.style.left = `${startX}px`
        balloon.style.top = `${startY}px`
        balloon.style.fontSize = `${Math.max(size/6, 12)}px`
        balloon.style.padding = '8px'
        balloon.textContent = title

        balloon.dataset.homeX = finalX
        balloon.dataset.homeY = finalY
        balloon.dataset.startX = startX
        balloon.dataset.startY = startY

        this.container.appendChild(balloon)
        this.setupDragging(balloon)

        let startTime = null
        const riseTime = 12 + (size / 40) * 3

        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp
            const progress = (timestamp - startTime) / (riseTime * 1000)

            if (progress < 1) {
                const easedProgress = 1 - Math.pow(1 - progress, 3)
                const horizontalPhase = Math.sin(progress * Math.PI * 2) * 30 * (1 - progress)

                const newY = startY - (startY - finalY) * easedProgress
                const newX = startX + (finalX - startX) * easedProgress + horizontalPhase

                balloon.style.transform = `translate(
                    ${newX - startX}px,
                    -${startY - newY}px
                )`

                requestAnimationFrame(animate)
            } else {
                this.startSwaying(balloon, finalX - startX, startY - finalY)
            }
        }

        requestAnimationFrame(animate)
    }

    setupDragging(balloon) {
        let isDragging = false
        let dragStartX = 0
        let dragStartY = 0
        let balloonStartX = 0
        let balloonStartY = 0

        const getCurrentPosition = () => {
            const rect = balloon.getBoundingClientRect()
            return {
                x: rect.left,
                y: rect.top,
                transform: balloon.style.transform
            }
        }

        const dragStart = (e) => {
            if (e.target !== balloon) return
            e.preventDefault()

            isDragging = true
            this.activeDrag = balloon
            balloon.style.animation = 'none'
            balloon.style.cursor = 'grabbing'
            balloon.style.zIndex = '100' // Add z-index when dragging

            const position = getCurrentPosition()
            balloonStartX = position.x
            balloonStartY = position.y

            if (e.type === "touchstart") {
                dragStartX = e.touches[0].clientX
                dragStartY = e.touches[0].clientY
            } else {
                dragStartX = e.clientX
                dragStartY = e.clientY
            }
        }

        const dragEnd = (e) => {
            if (!isDragging) return

            isDragging = false
            this.activeDrag = null
            balloon.style.cursor = 'grab'
            balloon.style.zIndex = '' // Remove z-index after dragging

            const position = getCurrentPosition()
            const startY = parseFloat(balloon.dataset.startY)
            const adjustedY = window.innerHeight - position.y - startY

            this.floatBack(balloon, position.x, adjustedY)
        }

        const drag = (e) => {
            if (!isDragging) return
            e.preventDefault()

            let currentX, currentY

            if (e.type === "touchmove") {
                currentX = e.touches[0].clientX
                currentY = e.touches[0].clientY
            } else {
                currentX = e.clientX
                currentY = e.clientY
            }

            const deltaX = currentX - dragStartX
            const deltaY = currentY - dragStartY
            const newX = balloonStartX + deltaX
            const newY = balloonStartY + deltaY

            const startX = parseFloat(balloon.dataset.startX)
            const startY = parseFloat(balloon.dataset.startY)

            balloon.style.transform = `translate(
                ${newX - startX}px,
                ${-(startY - newY)}px
            )`
        }

        balloon.addEventListener('pointerdown', dragStart)
        document.addEventListener('pointerup', dragEnd)
        document.addEventListener('pointermove', drag)

        balloon.addEventListener('touchstart', e => e.preventDefault(), { passive: false })
        balloon.addEventListener('touchmove', e => e.preventDefault(), { passive: false })
    }

    floatBack(balloon, currentX, currentY) {
        const startX = parseFloat(balloon.dataset.startX)
        const startY = parseFloat(balloon.dataset.startY)
        const homeX = parseFloat(balloon.dataset.homeX)
        const homeY = parseFloat(balloon.dataset.homeY)

        let startTime = null
        const floatDuration = 2000

        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp
            const progress = (timestamp - startTime) / floatDuration

            if (progress < 1) {
                const eased = this.elastic(progress)

                const newX = currentX + (homeX - currentX) * eased
                const newY = currentY + (homeY - currentY) * eased

                balloon.style.transform = `translate(
                    ${newX - startX}px,
                    ${-(startY - newY)}px
                )`

                requestAnimationFrame(animate)
            } else {
                this.startSwaying(balloon, homeX - startX, startY - homeY)
            }
        }

        requestAnimationFrame(animate)
    }

    elastic(t) {
        return Math.pow(2, -10 * t) * Math.sin((t - 0.075) * (2 * Math.PI) / 0.3) + 1
    }

    startSwaying(balloon, xOffset, yOffset) {
        const uniqueId = Math.floor(Math.random() * 10000)
        const size = parseFloat(balloon.style.width)

        const duration = 3 + (size / 20)
        const swayAmount = 10 + (size / 8)
        const floatAmount = 5 + (size / 12)

        const swayKeyframe = `
            @keyframes sway-${uniqueId} {
                0% {
                    transform: translate(${xOffset}px, ${-yOffset}px);
                }
                25% {
                    transform: translate(${xOffset + swayAmount}px, ${-yOffset - floatAmount}px)
                        rotate(${swayAmount/15}deg);
                }
                50% {
                    transform: translate(${xOffset}px, ${-yOffset}px);
                }
                75% {
                    transform: translate(${xOffset - swayAmount}px, ${-yOffset + floatAmount}px)
                        rotate(${-swayAmount/15}deg);
                }
                100% {
                    transform: translate(${xOffset}px, ${-yOffset}px);
                }
            }
        `

        const existingStyle = document.getElementById(`sway-${uniqueId}`)
        if (existingStyle) {
            existingStyle.remove()
        }

        const styleSheet = document.createElement('style')
        styleSheet.id = `sway-${uniqueId}`
        styleSheet.textContent = swayKeyframe
        document.head.appendChild(styleSheet)

        balloon.style.transition = 'none'
        balloon.style.animation = `sway-${uniqueId} ${duration}s ease-in-out infinite`
    }

    cleanUp() {
        const balloons = document.querySelectorAll(".balloon-instance")
        balloons.forEach(balloon => balloon.remove())
        const balloonContainers = document.querySelectorAll(".balloon-container")
        balloonContainers.forEach(container => container.remove())
    }
}
