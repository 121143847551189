import { Controller } from "@hotwired/stimulus"

// https://docs.shepherdpro.com/guides/usage/
import Shepherd from 'shepherd.js';

export default class extends Controller {
  static values = {
    content: String,
    stepsKey: String
  }

  connect() {

    // Check if the tour has already been completed
    if (localStorage.getItem('tourCompleted') !== 'true') {
        this.initializeTour();
        this.tour.start();
      }
  }

  getVideoEmbedHTMLString(url) {
    return `
      <div class="w-[640px] w-max-full aspect-video overflow-hidden rounded-md">
        <div class="embed-container">
          <iframe src="${url}"
            frameborder="0" 
            allow="autoplay; fullscreen" allowfullscreen
            style="position:absolute;top:0;left:0;width:100%;height:100%;">
          </iframe>
        </div>
      </div>
    `
  }

  initializeTour() {  
    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'bg-white shadow-md rounded-md',
        scrollTo: {behavior: 'smooth', block: 'center'},
        arrow: true
      }
    });

    // const videoStepExample = {
    //   id: 'flourishing-tour-step-1',
    //   text: this.getVideoEmbedHTMLString('https://player.vimeo.com/video/807948230?h=32de73b859&title=0&byline=0&portrait=0') + '<p>Cool video</p>',
    //   buttons: backNextButtons
    //   ]
    // };

    const backNextButtons = { buttons: [
      { text: 'Back', action: this.tour.back },
      { text: 'Next', action: this.tour.next }
    ] };
    const endButtons = { buttons: [
      { text: 'Back', action: this.tour.back }, 
      { text: 'Thanks!', action: this.tour.next }
    ]};

    const weeklyReflectionStep = {
      id: 'reflection',
      text: 'Here you can do a weekly reflection and turn on a weekly reflection reminder.',
      attachTo: {
        element: '#dashboard-weekly-reflection',
        on: 'bottom'
      },
      ...backNextButtons
    };
    const downloadAppStep = {
      id: 'download-app',
      text: 'We suggest you download the app for the best timer experience!',
      attachTo: {
        element: '#maketimeflow-download-app-toast-001',
        on: 'bottom'
      },
      ...backNextButtons
    };
    const ritualsStep = {
      id: 'dashboard-rituals',
      text: 'These are your rituals. You can turn on ritual reminders here.',
      attachTo: {
        element: '#dashboard-rituals',
        on: 'bottom'
      },
      ...backNextButtons
    };
    const focusStep = {
      id: 'dashboard-focus',
      text: 'Here you can set your focus for today and track your intentional time.',
      attachTo: {
        element: '#dashboard-focus',
        on: 'bottom'
      },
      ...backNextButtons
    };

    const activeExperimentsStep = {
      id: 'dashboard-active-experiments',
      text: 'This is your active experiment description. You can end or edit it at any time.',
      attachTo: {
        element: '#dashboard-active-experiments',
        on: 'bottom'
      },
      ...backNextButtons
    }

    const compassStep = {
      id: 'dashboard-flourishing-compass',
      text: 'This is your flourishing compass. You can use it to check out how much you flourish.',
      attachTo: {
        element: '#dashboard-flourishing-compass',
        on: 'bottom'
      },
      ...backNextButtons
    }

    const goalsStep = {
      id: 'dashboard-goals',
      text: 'This is your goals section. You can view and redefine your goals here.',
      attachTo: {
        element: '#dashboard-goals',
        on: 'bottom'
      },
      ...backNextButtons
    }

    const startExperimentStep = {
      id: 'dashboard-start-experiment',
      text: 'You can start an experiment here. Just click on the button and follow the steps.',
      attachTo: {
        element: '#dashboard-start-experiment',
        on: 'bottom'
      },
      ...backNextButtons
    };

    const timerStep = {
      id: 'dashboard-go-to-timer',
      text: 'To get started for now, go to the timer by clicking here!',
      attachTo: {
        element: '#dashboard-go-to-timer',
        on: 'bottom'
      }
    }

    const commonSteps = [
      {
        id: 'dashboard-start',
        text: 'Welcome to MakeTimeFlow! Want a quick tour?',
        buttons: [ { text: 'Sure!', action: this.tour.next }, { text: 'Skip for now', action: this.tour.cancel }, { text: 'No thanks', action: this.tour.complete } ]
      }
    ]
    
    const DASHBOARD_TOUR_STEP_OF = {
      focus: [
        ...commonSteps,
        activeExperimentsStep,
        focusStep,
        weeklyReflectionStep,
        downloadAppStep,
        timerStep,
      ],
      rituals: [
        ...commonSteps,
        activeExperimentsStep,
        ritualsStep,
        weeklyReflectionStep,
        {
          ...ritualsStep,
          text: 'To get started, you can turn on ritual reminders here. Happy flowing!',
          ...endButtons
        }
      ],
      flourishing: [
        ...commonSteps,
        compassStep,
        startExperimentStep,
        goalsStep,
        {
          ...weeklyReflectionStep,
          ...endButtons
        },
      ],
      workbook: [
        ...commonSteps,
        activeExperimentsStep,
        ritualsStep,
        {
          ...weeklyReflectionStep,
          ...endButtons
        },
      ],
      default: [
        ...commonSteps,
        compassStep,
        startExperimentStep,
        goalsStep,
        {
          ...weeklyReflectionStep,
          ...endButtons
        },
      ]
    };

    // Add steps to the tour
    const stepsKeyValue = this.stepsKeyValue || 'default';
    DASHBOARD_TOUR_STEP_OF[stepsKeyValue].forEach((step) => {
      this.tour.addStep(step);
    });

    // Listen for the tour's completion or cancellation to set localStorage
    // this.tour.on('cancel', () => localStorage.setItem('tourCompleted', 'true'));
    this.tour.on('complete', () => localStorage.setItem('tourCompleted', 'true'));
  }

  disconnect() {
    if (this.tour) {
      this.tour.cancel();
    }
  }

  // Method to manually start the tour, ignoring localStorage
  startTour() {
    if (!this.tour) {
      this.initializeTour();
    }
    this.tour.start();
  }
}
