import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["message", "anchor"]

    connect() {
        console.log("ChatController.connect")
        this.scrollToBottom()
    }

    messageTargetConnected(target) {
        console.log("ChatController.messageTargetConnected")
        this.scrollToBottom()
    }

    scrollToBottom() {
        this.anchorTarget.scrollIntoView({behavior: "smooth"})
    }
}