import { Controller } from "@hotwired/stimulus"

// This controller is used to make all links in a rich text field open in the top frame
// and so avoid the "Content missing" error when they are used in a turbo-frame
export default class extends Controller {
  connect() {
    console.log("RichText controller connected")
    this.element.querySelectorAll('a').forEach(function(link) {
        link.target = "_top"
    })
  }
}