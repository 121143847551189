import { Controller } from "@hotwired/stimulus"
import { Dismiss } from 'flowbite';

export default class extends Controller {

    static values = {
        timeout: Number
    }

    connect() {
        if (this.timeoutValue) {
            const dismiss = new Dismiss(this.element);
            setTimeout(() => {
                dismiss.hide();
            }, this.timeoutValue)
        }
    }

}