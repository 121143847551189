import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    status: String,
    id: Number,
    task: String,
    createdAt: String,
    durationSec: Number,
    button: Number,
    startedFrom: String,
    stoppedAt: String,
    relatedTaskId: Number,
    refreshInterval: { type: Number, default: 1000 }
  }

  connect() {
    if (this.statusValue !== "stopped") {
      this.start()

      const stopButton = document.getElementById('stopButton')
      stopButton.addEventListener('click', () => {
        this.stop()
      })
    } else {
      this.stop()
    }
  }

  // when the controller disconnects it does not necessarily mean we should send a stop signal
  // so we do not send any signal. The system should append a controller that has the latest timer status
  // that should trigger the appropriate stop

  start() {
    if (typeof window.electronAPI.onStartTimerV2 === "function") {
      window.electronAPI.onStartTimerV2(this.idValue, this.taskValue, this.durationSecValue, this.buttonValue, this.startedFromValue, this.createdAtValue, this.statusValue, this.relatedTaskIdValue)
    } else if (typeof window.electronAPI.onStartTimer === "function") {
      window.electronAPI.onStartTimer(this.idValue, this.taskValue, this.durationSecValue, this.buttonValue, this.startedFromValue, this.createdAtValue, this.statusValue)
    } else {
      console.warn("onStartTimerV2 is not available")
    }
  }

  stop() {
    window.electronAPI.onStopTimer(this.idValue)
  }
}
