/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

import "flowbite/dist/flowbite.turbo.js"
import "flowbite-datepicker"
import "flowbite/dist/datepicker.turbo.js"

import "chartkick/chart.js"
import annotationPlugin from 'chartjs-plugin-annotation';


//Chart.register(annotationPlugin);
Chartkick.adapters[0].library.register(annotationPlugin);

// Start Rails UJS
Rails.start()
