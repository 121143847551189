import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        const form = this.form();

        if (form) {
            this.initialState = new FormData(form);
            this.setupForm(form);
        } else {
            console.warn('No form found');
        }
    }

    form() {
        let form;
        if (this.element.tagName === 'FORM') {
            form = this.element;
        } else {
            form = this.element.querySelector('form');
        }
        return form;
    }

    setupForm(form) {
        // subclasses will implement
    }

    restoreState() {
        const form = this.form();
        if (!form || !this.initialState) return;

        form.reset();
        for (const [name, value] of this.initialState.entries()) {
            const input = form.querySelector(`[name="${name}"]`);
            if (input && input.type !== 'file') {
                input.value = value;
            }
        }
    }
}
