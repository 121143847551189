import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown"
export default class extends Controller {
  static targets = ["countdown", "graph"]


  static values = {
    status: String,
    timerId: Number,
    createdAt: String,
    durationSec: Number,
    stoppedAt: String,
    seconds: { type: Boolean, default: true },
    title: String,
    refreshInterval: { type: Number, default: 1000 }
  }

  connect() {
    this.start()
  }

  disconnect() {
    this.stop()
  }

  start() {
    this.sentAlarm = false

    if (this.statusValue == "stopped") {
      this.countdownTarget.innerHTML = "Stopped"
      return
    }

    this.startTime = new Date(this.createdAtValue)
    if (this.dateIsValid(this.startTime)) {
      if (this.durationSecValue > 0) {
        this.endTime = new Date(this.startTime.getTime() + (this.durationSecValue * 1000))
        this.timer = setInterval( () => { this.update() }, this.refreshIntervalValue )
        this.update()
      }
      else {
        this.error("Duration is not a number or is less than 0")
      }
    } else {
      this.error("Start time is not a valid date!")
    }
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  update() {

    if(this.hasGraphTarget) {
      this.graphTarget.setAttribute('data-timer-graph-ms-passed-value', this.msPassed);
      this.graphTarget.setAttribute('data-timer-graph-duration-sec-value', this.durationSecValue);

      let graphController = this.application.getControllerForElementAndIdentifier(this.graphTarget, "timer-graph");
      if(graphController) {
        graphController.update();
      }
    }

    if (this.timer) {
      let msRemaining = this.msRemaining
      let countdownText = this.remainingToStr(msRemaining)

      if (msRemaining<0) {
        // expired
        this.countdownTarget.classList.add("text-red-600")

        if (!this.sentAlarm) {
          this.dispatch("alarm", { detail: { id: this.timerIdValue } })
          this.sentAlarm = true
        }
      }

      if (this.countdownTarget.innerHTML != countdownText) {
        this.countdownTarget.innerHTML = countdownText
        if (this.hasTitleValue) {
          document.title = `${this.titleValue} - ${countdownText}`
        } else {
          document.title = `MakeTimeFlow - ${countdownText}`
        }
      }
    }
  }

  get msRemaining() {
    return this.endTime - Date.now()
  }

  get msPassed() {
    return Date.now() - this.startTime
  }

  remainingToStr(msRemaining) {
    let absRemaining = Math.abs(msRemaining)

    let hours = Math.floor(absRemaining / (1000 * 60 * 60));
    let minutes = Math.floor((absRemaining % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((absRemaining % (1000 * 60)) / 1000);

    var text = ""
    if (absRemaining > (1000 * 60 * 60)) {
      if (this.secondsValue && this.msPassed<10*1000) {
        text = `${hours}:${this.pad(minutes)}:${this.pad(seconds)}`
      } else {
        text = `${hours}:${this.pad(minutes)}`
      }
    } else if (absRemaining > (1000 * 60)) {
      if (this.secondsValue && this.msPassed<10*1000) {
        text = `${this.pad(minutes)}:${this.pad(seconds)}`
      } else {
        text = `${this.pad(minutes)}`
      }
    } else {
      // only seconds
      text = `${this.pad(seconds)}`
    }
    if (msRemaining < 0) {
      text = "- " + text
    }
    return text
  }

  error(msg) {
    // to consider: make this an error icon with the error msg as a tooltip
    this.countdownTarget.innerHTML = `<div class="text-red">${msg}</div>`
  }

  // Utility methods
  // from https://bobbyhadz.com/blog/javascript-check-if-date-is-valid
  dateIsValid(date) {
    return date instanceof Date && !isNaN(date)
  }

  pad(num) {
    return String(num).padStart(2, "0")
  }

}
