import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    setTimeout( () => {
      this.element.parentNode.removeChild(this.element);
    }, 2200)
  }

}
