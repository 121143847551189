import { Controller } from "@hotwired/stimulus"

// Usage:
// Add the controller to the form
// <form data-controller="has-form-changed">
// Add the confirm method to your cancel button or link
// <%= link_to "Cancel", tasks_path, class: "btn btn-danger", data: { action: "click->has-form-changed#confirm" } %>
export default class extends Controller {

    connect() {
        this.originalFormData = new FormData(this.element)
    }

    confirm(event) {
        if (this.hasChanged()) {
            if (!confirm("Are you sure you want to leave this page? Changes you made may not be saved.")) {
                event.preventDefault()
            }
        }
    }

    hasChanged() {
        const currentFormData = new FormData(this.element)
        return [...this.originalFormData].some(([key, value]) => {
            return currentFormData.get(key) !== value
        })
    }
}