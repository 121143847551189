import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    launchAll(event) {
        event.preventDefault()
        const urls = JSON.parse(event.currentTarget.dataset.urls)

        if (window.electronAPI) {
            urls.forEach(url => window.electronAPI.openExternal(url))
            return
        }

        urls.forEach(url => window.open(url, "_blank"))
    }
}
