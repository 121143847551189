import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        "button": String, // button id
    }

    connect() {
        this.isConnected = true;
        this.updateForm();

        document.addEventListener("turbo:submit-end", this.updateForm.bind(this));
    }

    disconnect() {
        document.removeEventListener("turbo:submit-end", this.updateForm.bind(this));
        this.isConnected = false;
    }

    click(event) {
        event.preventDefault();
        this.element.classList.add('hidden');
    }

    updateForm() {
        if (!this.isConnected) {
            return;
        }

        if (this.element.parentElement) {
            // give the form time to render
            setTimeout(() => {
                this.setupForm();
            }, 100);
        }
    }

    setupForm() {
        if (!this.isConnected) {
            return;
        }
        const button = document.getElementById(this.buttonValue);
        if (button) {
            button.addEventListener("click", this.click.bind(this));
        } else {
            console.error(`Could not find button with id ${this.buttonValue}`);
        }
    }
}
