import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";

// This controller is used to copy the value of an input element or a link to another input element.
//
// This can be useful when you have a page that cannot have overlapping forms
// you can then place an element outside of the form and copy the value to the form input.
// target = the id of the input element to copy the value to
// You also need to make sure you add the data-action="input->input-copy#copy" to the input element you want to copy
// Example:
// <input type="text" id="input1" data-controller="input-copy" data-action="input-copy#copy" data-input-copy-target="input2">
//
// You can also use this controller to copy the value of a link to an input element.
// Example:
// <a href="#" id="link1" data-controller="input-copy" data-action="click->input-copy#copy" data-input-copy-target-value="input2">Copy</a>
export default class extends Controller {
    static values = {
        target: String,
        source: String, // optional, if not set, the value of the element will be copied
        action: String, // optional, "replace" (default) or "append"
        appendWith: String, // optional, original + appendWith + source
        showTooltip: Boolean // optional, default is false
    }

    tooltip(message) {
        if (!this.showTooltipValue) {
            return
        }

        tippy(this.element, {
            content: message,
            showOnCreate: true,
            onHidden: (instance) => {
                instance.destroy()
            }
        })
    }

    copy() {
        console.log("Copying value to target")
        const target = document.getElementById(this.targetValue)

        if (!target) {
            console.error("InputCopy: missing target value")
            return
        }

        let source
        if (this.sourceValue) {
            source = document.getElementById(this.sourceValue)
            if (!source) {
                console.error(`InputCopy: missing source value ${this.sourceValue}`)
                return
            }
        } else {
            source = this.element
        }

        const action = this.actionValue || "replace"

        let newValue

        if (source.tagName === "A") {
            newValue = source.innerHTML
        } else {
            newValue = source.value
        }

        if (action == "replace") {
            target.value = newValue
            this.tooltip("Copied!")
        } else {
            let oldValue = target.value

            if (this.appendWithValue) {
                if (oldValue && oldValue.length > 0) {
                    newValue = oldValue + this.appendWithValue + newValue
                }
            }
            target.value = newValue
            this.tooltip("Copied!")
        }
    }
}