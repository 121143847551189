import { Controller } from "@hotwired/stimulus"

export default class extends Controller {   
    static targets = ["modal","player"];

    disconnect() {
        this.close();
    }

    close(event) {
        if(event) { event.preventDefault(); }
        this.modalTarget.classList.remove("open");
        this.destroyVimeoPlayer();
    }

    open(event) {
        event.preventDefault();
        this.modalTarget.classList.add("open");
        const { url } = event.params;
        this.loadVimeoPlayer(url);
    }

    loadVimeoPlayer(url) {
        const iframe = document.createElement("iframe");
        iframe.src = `${url}&autoplay=1&controls=0`;
        iframe.width = "100%";
        iframe.height = "100%";
        iframe.allow = "autoplay; fullscreen";
        this.playerTarget.appendChild(iframe);

        // Add event listener for 'finish' event
        const player = new Vimeo.Player(iframe);
        player.on("ended", () => {
            player.destroy();
            this.close();
        });
    }

    destroyVimeoPlayer() {
        while (this.playerTarget.firstChild) {
            this.playerTarget.removeChild(this.playerTarget.firstChild);
        }
    }
}
