import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["slider", "value", "label"]

    connect() {
        this.updateLabel()
    }

    updateLabel() {
        const value = this.sliderTarget.value
        this.valueTarget.textContent = value
        this.labelTarget.textContent = this.getLabel(value)
    }

    getLabel(value) {
        const val = parseInt(value)
        if (val <= 10) return "Just starting out / Haven't begun yet"
        if (val <= 30) return "Taking baby steps / Testing the waters"
        if (val <= 50) return "Making progress but lots of room to grow"
        if (val <= 70) return "Solidly on track / Seeing real results"
        if (val <= 90) return "Thriving but still excited to improve"
        return "Living my best life in this area!"
    }

    increase(event) {
        let points = parseInt(this.sliderTarget.step)
        if (event.params.points) {
            points = parseInt(event.params.points)
        }
        this.sliderTarget.value = parseInt(this.sliderTarget.value) + points
        this.updateLabel()
    }

    decrease(event) {
        let points = parseInt(this.sliderTarget.step)
        if (event.params.points) {
            points = parseInt(event.params.points)
        }

        this.sliderTarget.value = parseInt(this.sliderTarget.value) - points
        this.updateLabel()
    }
}

