import {Controller} from "@hotwired/stimulus"
import confetti from "canvas-confetti"

// from https://raw.githubusercontent.com/avo-hq/stimulus-confetti/main/src/index.js

const inlineStylesFormObject = (styles = {}) => {
  const resultAsArray = Object.keys(styles).map((property) => {
    const value = styles[property]

    return `${property}:${value}`
  })

  return resultAsArray.join(";")
}

const BLUE_COLORS = ["#7eb0ff","#3d76d4","#4a8dff","#0c172a","#192f55","#25467f"];

const GREEN_COLORS = [
  '#C9FFE6',
  '#A2FFD5',
  '#7CFFC4',
  '#55FFB3',
  '#2EFFA2',
  '#00E691',
  '#00B373',
  '#008E55',
  '#006738',
  '#00411A'        
];

const PURPLE_COLORS = [
  '#27CFE6', 
  '#72b2ff', 
  '#8fc1ff', 
  '#a7d3ff', 
  '#bde5ff',  
  '#d4f6fe', 
  '#ecffff', 
  '#e0ddff', 
  '#c4bbff', 
  '#A45CFF'
];


export default class extends Controller {
  static targets = ["element"]

  static values = {
    follow: {
      type: Boolean,
      default: true
    },

    // Type of animation basic|school-pride|stars
    animation: {
      type: String,
      default: 'basic'
    },
    xOrigin: {
      type: Number,
      default: 0.5
    },
    yOrigin: {
      type: Number,
      default: 0.5
    },
    duration: {
      type: Number,
      default: 5 // number of seconds
    },

    colorScheme: {
      type: String,
      default: 'basic'
    },

    firstColor: {
      type: String,
      default: "#0886DE"
    },

    secondColor: {
      type: String,
      default: "#FF6154"
    },

    particleCount: {
      type: Number,
      default: 100
    },
    startVelocity: {
      type: Number,
      default: 20
    },
    spread: {
      type: Number,
      default: 360
    },
    auto: {
      type: Boolean,
      default: false
    },
    ticks: {
      type: Number,
      default: 90
    },
    delayMillis: {
      type: Number,
      default: 0
    },
    debug: {
      type: Boolean,
      default: false
    },
    canvasId: {
      type: String,
      default:''
    }
  }

  clicked = false
  canvasElement = null

  get canvas() {

    this.log("canvas getter called")
    if (this.canvasElement) {
      return this.canvasElement
    }

    if (this.canvasIdValue) {
      // Use ID to fetch canvas
      this.canvasElement = document.getElementById(this.canvasIdValue)
    }
    else {
      // Create a new canvas as big as the screen and make it fixed and ontop of everything
      const myCanvas = document.createElement("canvas")
      myCanvas.style.cssText = inlineStylesFormObject({
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        "pointer-events": "none",
        "z-index": "9999",
      })

      // Add it to the DOM
      document.body.appendChild(myCanvas)

      // Cache it
      this.canvasElement = myCanvas
    }

    return this.canvasElement
  }

  get instance() {
    return confetti.create(this.canvas, {
      resize: true,
      useWorker: true,
    })
  }

  connect() {
    this.log('Connected', {
      auto: this.autoValue,
      delay: this.delayMillisValue
    })
    if(this.autoValue) {
      if (this.delayMillisValue) {
        if (this.timerId) { clearTimeout(this.timerId) }
        this.timerId = setTimeout( this.journeyComplete(), this.delayMillisValue)
      }
      else {
        this.journeyComplete()
      }
    }
  }

  disconnect() {
    if (this.timerId) { clearTimeout(this.timerId) }
  }

  spray(e) {
    //e.preventDefault()

    this.log("Spraying")

    this.getStyle(e).then(() => {
      this.log("Finished animation")
      if (this.followValue) {
        // Actually follow the link
        if (this.clicked === false) {
          this.followLink()
        }

        this.clicked = true
      }
    })
  }

  spray_if_checked(e) {
    if (e.target.checked) {
      this.spray(e)
    }
  }

  async getStyle(e) {
    switch (this.animationValue) {
      case 'basic':
      default:
        return this.basic(e)
      case 'school-pride':
        return this.schoolPride(e)
      case 'stars':
        return this.stars(e)
      case 'custom':
        return this.custom(e)
    }
  }

  basic(e) {
    this.log("Basic started")

    var rect = e.target.getBoundingClientRect();
    var x = rect.left + (rect.width / 2);
    var y = rect.top + (rect.height / 2);

    let colors;
    switch (this.colorSchemeValue) {
      case 'blue':
        colors = BLUE_COLORS;
        break;
      case 'basic':
      default:
        break;
    }

    const confettiArgs = {
      particleCount: this.particleCountValue,
      startVelocity: this.startVelocityValue,
      spread: this.spreadValue,
      ticks: this.ticksValue,
      delay: this.delayMillisValue,
      origin: {
        x: x / window.innerWidth,
        y: y / window.innerHeight,
      },
    };

    if (colors) {
      confettiArgs.colors = colors;
    }

    return this.instance(confettiArgs);
  }

  stars(e) {
    this.log("Stars started")

    var defaults = {
      spread: this.spreadValue,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: this.startVelocityValue,
      shapes: ['star'],
      colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
      origin: {
        x: e.clientX / window.innerWidth,
        y: e.clientY / window.innerHeight,
      },
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        this.instance({
          ...defaults,
          particleCount: this.particleCountValue,
          // scalar: 1.2,
          shapes: ['star']
        });
      }, 70);

      this.instance({
        ...defaults,
        particleCount: this.particleCountValue,
        // scalar: 0.75,
        shapes: ['star']
      }).then(() => {
        this.log("Stars ended")
        resolve()
      });
    })
  }

  schoolPride(e) {
    this.log("School pride started")
    return new Promise((resolve) => {
      var end = Date.now() + (this.durationValue * 1000);
      // go Buckeyes!
      var colors = [this.firstColorValue, this.secondColorValue];
      const vm = this;

      (function frame() {
        vm.instance({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors: colors
        });
        vm.instance({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: colors
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        } else {
          vm.log("School pride ended")
          resolve()
        }
      })();
    })
  }

  followLink() {
    this.log("Following link")
    // Create a link
    const link = document.createElement("a")
    link.style.cssText = inlineStylesFormObject({
      opacity:0,
      display: "hidden"
    })
    link.href = this.context.element.href
    link.target = this.context.element.target
    // Add it to the DOM
    document.body.appendChild(link)
    // Click it
    link.click()
    this.log("Followed link")

    // Mark it as clicked and reset the state
    setTimeout(() => {
      this.log("Reset state")
      this.clicked = false
    }, 1);
  }

  journeyComplete() {
    this.log('JourneyComplete started');

    const confettiArgs = {
      spread: 70,
      zIndex: 100,
      colors: PURPLE_COLORS
    };

    this.instance({
      ...confettiArgs,
      origin: {
        y: this.yOriginValue,
        x: this.xOriginValue
      },
      particleCount: 100,
    });
    this.instance({
      ...confettiArgs,
      origin: {
        y: this.yOriginValue,
        x: this.xOriginValue - 0.25
      },
    });
    this.instance({
      ...confettiArgs,
      origin: {
        y: this.yOriginValue,
        x: this.xOriginValue + 0.25
      },
    });
    

    return;

  }

  custom(e) {
    this.log('Custom started');

    const confettiArgs = {
      spread: 70,
      zIndex: 100,
      colors: GREEN_COLORS
    }

    this.instance({
      ...confettiArgs,
      origin: {
        y: 0.75,
        x: 0.5
      },
      particleCount: 100,

    });
    this.instance({
      ...confettiArgs,
      origin: {
        y: 0.75,
        x: 0.75,
      },
    });
    this.instance({
      ...confettiArgs,
      origin: {
        y: 0.75,
        x: 0.25
      },
    });
    

    return;
  }

  log(message, obj) {
    if (this.debugValue) {
      if(obj) {
        return console.log(`[Stimulus confetti] ${message}`, obj)
      }
      console.log(`[Stimulus confetti] ${message}`)
    }
  }
}

