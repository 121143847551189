import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    const stars = this.element.querySelectorAll('.star');
    const ratingInput = this.inputTarget;
    const currentRating = ratingInput.value;
    // currentRating is a string, so we convert it to an integer
    const currentRatingInt = parseInt(currentRating, 10);

    stars.forEach((star, index) => {
      this.setStar(star, index < currentRatingInt);

      star.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();

        // Check if the clicked star is already solid (selected)
        const isSolid = !star.querySelector('.star-solid').classList.contains('hidden');

        // Determine the new rating based on the click
        let newRating;
        if (isSolid) {
          newRating = index;
        } else {
          newRating = index + 1;
        }

        ratingInput.value = newRating;

        // Update stars based on the new rating
        stars.forEach((otherStar, otherIndex) => {
          this.setStar(otherStar, otherIndex < newRating);
        });
      });
    });
  }

  disconnect() {
    // This is a Stimulus lifecycle method that gets called when the controller is removed from the page
    // In this case, we want to remove the event listener that we added in the connect method
    const stars = this.element.querySelectorAll('.star');
    stars.forEach((star) => {
      star.removeEventListener('click', () => {});
    });
  }

  setStar(star, on) {
    if (on) {
      star.querySelector('.star-outline').classList.add('hidden');
      star.querySelector('.star-solid').classList.remove('hidden');
    } else {
      star.querySelector('.star-outline').classList.remove('hidden');
      star.querySelector('.star-solid').classList.add('hidden');
    }
  }

}

