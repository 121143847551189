import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["jsonInput", "checkmark", "advanced", "paramText", "paramInput", "paramDialog"]

    connect() {
        this.updateCheckmarks()
    }

    toggleAdvanced(event) {
        this.advancedTarget.classList.toggle("hidden")
        event.currentTarget.querySelector("svg").classList.toggle("rotate-180")
    }

    toggleTool(event) {
        const toolCard = event.currentTarget
        const toolName = toolCard.dataset.toolName
        const toolUrl = toolCard.dataset.toolUrl
        const requiresParams = toolCard.dataset.requiresParams === "true"

        const isSelected = toolCard.dataset.selected === "true"
        if (!isSelected && requiresParams) {
            this.paramDialogTarget.classList.remove("hidden")
            this.paramTextTarget.innerHTML = toolCard.dataset.paramPrompt
            this.paramInputTarget.value = ""
            this.currentToolCard = toolCard
            return
        }

        this.addOrRemoveTool(toolCard, toolUrl)
    }

    submitParam(event) {
        event.preventDefault()
        const param = this.paramInputTarget.value
        if (!param) return

        const toolUrl = this.currentToolCard.dataset.toolUrl
        let finalUrl = param.startsWith("http") ? param : toolUrl.replace("${id}", param)

        this.addOrRemoveTool(this.currentToolCard, finalUrl)

        this.paramInputTarget.value = ""
        this.paramDialogTarget.classList.add("hidden")
    }

    addOrRemoveTool(toolCard, finalUrl) {
        const toolName = toolCard.dataset.toolName
        const currentTools = this.getCurrentTools()
        const toolIndex = this.findToolIndex(currentTools, toolName)

        if (toolIndex === -1) {
            currentTools.push({name: toolName, url: finalUrl})
        } else {
            currentTools.splice(toolIndex, 1)
        }

        this.jsonInputTarget.value = JSON.stringify(currentTools, null, 2)
        this.updateCheckmarks()
    }

    getCurrentTools() {
        try {
            const tools = JSON.parse(this.jsonInputTarget.value)
            // Ensure we always return an array, even if the parsed value is null or not an array
            return Array.isArray(tools) ? tools : []
        } catch {
            return []
        }
    }

    findToolIndex(tools, name) {
        if (!Array.isArray(tools)) {
            console.warn('Expected tools to be an array, got:', typeof tools)
            return -1
        }
        return tools.findIndex(tool => tool.name === name)
    }


    updateCheckmarks() {
        const currentTools = this.getCurrentTools()
        this.element.querySelectorAll("[data-tool-name]").forEach(card => {
            const checkmark = card.querySelector("[data-communication-tools-target='checkmark']")
            const isSelected = this.findToolIndex(currentTools, card.dataset.toolName) !== -1
            checkmark.classList.toggle("hidden", !isSelected)
            card.dataset.selected = isSelected ? "true" : "false"
        })
    }
}
