import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        id: String,
        focus: String,
        url: String
    }

    next() {
        if (this.urlValue) {
            Turbo.visit(this.urlValue)
            return
        }

        if (!this.idValue) {
            console.error("ShowNext: missing id value")
            return
        }

        this.element.classList.add("hidden")
        const next = document.getElementById(this.idValue)
        next.classList.remove("hidden")
        next.classList.add("fade-in")

        const focus = document.getElementById(this.focusValue)
        if (focus) {
            setTimeout(() => {
                focus.focus()
            }, 100)
        }
    }
}
