import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "minutes",
    "task",
    "button",
    "priority",
    "moreSection",
    "form"
  ]
  static values = {
    "tipContainerId": String,
    "tipTogglerId": String,
    "showMore": Boolean
  }

  toggleLabels() {
    const timer = document.getElementById('box-timer');
    timer.classList.toggle('box-timer--labels-hidden');
  }

  toggleTip(event) {
    const tipContainer = document.getElementById(this.tipContainerIdValue);
    
    if(tipContainer) {
      this.element.classList.toggle("mt-16");
      tipContainer.classList.toggle("hidden");

      const tipToggler = document.getElementById(this.tipTogglerIdValue);
      if(tipToggler) {
        tipToggler.classList.toggle("hidden");
      }
    }
  }

  connect() {
    if (this.showMoreValue) {
      this.toggleLabels()
    }
  }

  startTimer() {
    var minutes = event.params["minutes"]

    // a button target is only needed if we are setting button params
    if (event.params["button"]) {
      if (this.hasButtonTarget) {
        this.buttonTarget.value = event.params["button"]
      } else {
        alert("Sorry, I don't have a button target to set!")
      }
    }

    if (event.params["priority"]) {
      if (this.hasPriorityTarget) {
        this.priorityTarget.value = event.params["priority"]
      } else {
        alert("Sorry, I don't have a priority target to set!")
      }
    }

    // I no longer set the task from the row, since it will now get set from button settings
    // or the user rows
    // if (event.params["row"]) {
    //   var project_for_priority = "device-row-"+event.params["row"]+"-text"
    //   var value = document.getElementById(project_for_priority).value
    //   if (value) {
    //     if (this.hasTaskTarget) {
    //       this.taskTarget.value = value
    //     } else {
    //       alert("Sorry, I don't have a task target to set!")
    //     }
    //   }
    // }

    if (this.hasMinutesTarget) {
      this.minutesTarget.value = minutes
      if (this.hasFormTarget) {
        this.formTarget.requestSubmit()
      } else {
        event.target.form.requestSubmit()
      }
    } else {
      alert("Sorry, I can't set the minutes, please click More... and set it manually")
    }
  }

  toggleMore(event) {
    if (this.hasMoreSectionTarget) {
      this.moreSectionTarget.classList.toggle("hidden") 
      if (this.moreSectionTarget.classList.contains("hidden")) {
        event.target.innerHTML = "More..."
      } else {
        event.target.innerHTML = "Less..."
      }
    }
  }
}
