import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "active",
    "closed",
    "notify",
    "feedback"
  ]
  static values = {
    "active": Boolean
  }

  connect() {
    this.update()
  }

  update() {
    this.setVisible(this.activeTarget, this.activeValue)
    this.setVisible(this.closedTarget, !this.activeValue)
  }

  notifyCoach() {
    this.showNotifyFeedback()
  }

  showNotifyFeedback() {
    this.setVisible(this.notifyTarget, false)
    this.setVisible(this.feedbackTarget, true)
  }

  toggle() {
    if (this.activeValue) {
      this.activeValue = false
    } else {
      this.activeValue = true
    }
    this.update()
  }

  close() {
    this.setVisible(this.element, false)
  }

  setVisible(obj, isVisible) {
    if (isVisible) {
      obj.classList.remove("hidden")
    } else {
      obj.classList.add("hidden")
    }
  }
}
