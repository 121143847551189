import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        console.log("scroll to bottom")
        this.scrollToBottom()
    }

    scrollToBottom() {
        this.element.scrollTop = this.element.scrollHeight
    }
}