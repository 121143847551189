import { Controller } from "@hotwired/stimulus"

// FIXME this is probably not needed; review and remove if not

export default class extends Controller {

    connect() {

        var select = this.element;
        var inputs = select.getElementsByTagName("input");
        for (var j = 0; j < inputs.length; j++) {
            var input = inputs[j];
            if (input.type === "radio") {
                if(document.activeElement === input) {
                    select.classList.add('focused');
                }
                input.addEventListener('focus', function() {
                    select.classList.add('focused');
                });
                input.addEventListener('blur', function() {
                    select.classList.remove('focused');
                });
            }
        }
    }

  
}
