import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "audio",
    "audioContainer",
    "notifyAllowed",
    "notifyNotSupported",
    "notifyAlert",
    "notifyDenied",
    "notifyNeedPermission",
    "errorMessage",
    "invalidAlarmSettings",
    "issueWithNotifications"
  ]

  static values = {
    timerBarId: String,
    enabled: Boolean,
    sound: Boolean,
    notify: Boolean,
    message: { type: String, default: "Your intentional timer has expired!" },
    checkSettings: { type: Boolean, default: true },
    alreadyAlarmed: { type: Array, default: [] }
  }

  connect() {
    if (this.enabledValue) {
      alarm()
    }

    if (this.checkSettingsValue) {
      this.checkSettings()
    }
  }

  // don't think there is anything to do on disconnect

  alarm({ detail: { id: id }}) {
    if (this.alreadyAlarmedValue.includes(id)) {
      return
    }

    if (this.soundValue) {
      this.soundAlarm()
    }

    if (this.notifyValue) {
      this.notifyAlarm()
    }

    this.alreadyAlarmedValue.push(id)
  }

  stopAlarm({ detail: {id: id}}) {
    if (this.soundValue) {
      this.silenceAlarm()
    }
  }

  soundAlarm() {
    if (this.hasAudioContainerTarget) {
      this.audioContainerTarget.classList.remove("hidden")
    }

    if (this.hasAudioTarget) {
      this.audioTarget.play()
    } else {
      this.error("No audio to play")
    }
  }

  silenceAlarm() {
    if (this.hasAudioTarget) {
      this.audioTarget.pause()
    }
    if (this.hasAudioContainerTarget) {
      this.audioContainerTarget.classList.add("hidden")
    }
  }

  notifyAlarm() {
    if (!this.browserSupportsNotification) {
      this.error("This browser does not support notifications.")
      return
    }

    if (Notification.permission != "granted") {
      if (Notification.permission == "denied") {
        console.error("You have denied us permission to send notifications. Unfortunately this means I cannot use notifications to let you that your timer has just expired.")
      } else {
        console.error("You have not yet given us permission to send notifications. Unfortunately this means I cannot use notifications to let you that your timer has just expired. Please enable notifications if you would like to be notified!")
      }
      return
    }

    new Notification(this.messageValue)
  }

  checkSettings() {
    // do they have a mezame (awakening) set?
    if (!(this.soundValue || this.notifyValue)) {
      this.invalidAlarmSettingsTarget.classList.remove("hidden")
    } else {
      this.invalidAlarmSettingsTarget.classList.add("hidden")
    }

    if (this.notifyValue) {
      // if notifications are set check notifications
      this.checkNotificationPermissions()
    }
  }

  get browserSupportsNotification() {
    return ("Notification" in window)
  }

  // here we just check but do not ask - since this needs to happen based on a user click event
  checkNotificationPermissions() {
    if (!this.browserSupportsNotification) {
      this.showPermissions({ notSupported: true })
    } else if (Notification.permission == "granted") {
      this.showPermissions({ allowed: true })
    } else if (Notification.permission == "denied") {
      this.showPermissions({ denied: true })
    } else {
      this.showPermissions({ needPermission: true})
    }
  }

  showPermissions({ allowed = false, needPermission = false, denied = false, notSupported = false } = {}) {
    this.showTarget(this.notifyAllowedTarget, allowed)
    this.showTarget(this.notifyNeedPermissionTarget, needPermission)
    this.showTarget(this.notifyDeniedTarget, denied)
    this.showTarget(this.notifyNotSupportedTarget, notSupported)
    this.showTarget(this.notifyAlertTarget, denied || notSupported)

    if (allowed) {
      this.issueWithNotificationsTarget.classList.add("hidden")
    } else {
      this.issueWithNotificationsTarget.classList.remove("hidden")
    }

    const nagsVisible = !allowed || !(this.soundValue || this.notifyValue);
    const timerBar = document.getElementById(this.timerBarIdValue)
    timerBar.toggleAttribute("data-nags-visible", nagsVisible);
  }

  showTarget(target, willShow) {
    if (willShow) {
      target.classList.remove("hidden")
    } else {
      target.classList.add("hidden")
    }
  }

  askForNotificationPermission() {
    const self = this
    Notification.requestPermission().then(function (permission) {
      self.checkNotificationPermissions()
    });
  }

  changeNotifyFromForm(event) {

    if (!event.target.checked) {
      this.showPermissions({})
      event.target.form.requestSubmit()
      return
    }

    if (!this.browserSupportsNotification) {
      this.showPermissions({ notSupported: true })
      event.target.checked = false
    } else if (Notification.permission == "granted") {
      this.showPermissions({ allowed: true })
      event.target.form.requestSubmit()
    } else if (Notification.permission == "denied") {
      this.showPermissions({ denied: true })
      event.target.checked = false
    } else {
        self = this
        self.notifyEvent = event
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            self.showPermissions({ allowed: true })
            self.notifyEvent.target.form.requestSubmit()
          } else {
            self.showPermissions({ denied: true })
            self.notifyEvent.target.checked = false
          }
        })
    }
  }

}

