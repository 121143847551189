import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        "cancel": String, // cancel button id
        "bucket": String,
        "area": String,
        "project": String,
        "updateTitleOnly": {type: Boolean, default: true}
    }

    slugify(str) {
        // downcase, and turn spaces into _
        return str.toLowerCase().replace(/\s+/g, "_")
    }

    get areaProjectValue() {
        let result = ""
        if (this.areaValue && this.areaValue != "") {
            result += `${this.areaValue}/`
        }
        if (this.projectValue && this.projectValue !== "") {
            result += `${this.projectValue}`
        }
        return this.slugify(result);
    }

    connect() {
        this.isConnected = true;
        this.updateForm();

        document.addEventListener("turbo:submit-end", this.updateForm.bind(this));
    }

    disconnect() {
        document.removeEventListener("turbo:submit-end", this.updateForm.bind(this));
        this.isConnected = false;
    }

    cancel(event) {
        event.preventDefault();
        this.element.classList.add('hidden');
    }

    updateForm() {
        if (!this.isConnected) {
            return;
        }

        if (this.element.parentElement) {
            // give the form time to render
            setTimeout(() => {
                this.setupForm();
            }, 100);
        }
    }

    setupForm() {
        if (!this.isConnected) {
            return;
        }
        const cancelButton = document.getElementById(this.cancelValue);
        if (cancelButton) {
            cancelButton.addEventListener("click", this.cancel.bind(this));
        } else {
            console.error("Cancel button not found");
        }

        if (this.updateTitleOnlyValue) {
            this.updateTitleField()
        } else {
            this.updateFormFields()
        }
    }

    updateTitleField() {
        var items = []
        if (this.bucketValue && this.bucketValue !== "") {
            // the all bucket is the same as leaving the bucket blank
            if (!['all', 'active'].includes(this.bucketValue)) {
                items.push(`@${this.bucketValue}`)
            }
        }
        if (this.areaProjectValue && this.areaProjectValue !== "") {
            items.push(`#${this.areaProjectValue}`)
        }
        if (items.length > 0) {
            // add a space at the end of the elements so the user can just start typing
            items.push("");

            const taskTitle = document.getElementById('task_title');
            if (taskTitle) {
                taskTitle.value = items.join(" ");
            } else {
                console.warn("Could not find task_title element in the form")
            }
        }
    }

    updateFormFields() {
        if (this.bucketValue && this.bucketValue !== "") {
            const taskBucket = document.getElementById('task_bucket');
            if (taskBucket) {
                taskBucket.value = this.bucketValue;
            } else {
                console.error("task bucket not found");
            }
        }

        if (this.areaValue && this.areaValue !== "") {
            const taskArea = document.getElementById('task_area');
            if (taskArea) {
                taskArea.value = this.areaValue;
            } else {
                console.error("task area not found");
            }
        }

        if (this.projectValue && this.projectValue !== "") {
            const taskProject = document.getElementById('task_project');
            if (taskProject) {
                taskProject.value = this.projectValue;
            } else {
                console.error("task project not found");
            }
        }
    }
}
