import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"

// Connects to data-controller="tribute"
export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    this.editor = this.element
    this.initializeTribute(this.optionsValue)
  }

  disconnect() {
    this.tribute.detach(this.editor)
  }

  initializeTribute(options) {
    this.tribute = new Tribute(this.optionsValue)
    this.tribute.attach(this.editor)
  }
}
