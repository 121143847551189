import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "help" ]

    static values = {
        delay: { type: Number, default: 5000 }
    }

    connect() {
        this.timeout = setTimeout(() => {
            this.show()
        }, this.delayValue)
    }

    show() {
        this.helpTarget.classList.remove("hidden")
    }

    hide() {
        this.helpTarget.classList.add("hidden")
    }
}
