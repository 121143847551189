import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'toggleable', 'toggler' ]
  static values = { open: Boolean }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  showPricing(event) {
    event.preventDefault();
    this.openValue = true;
  }

  openValueChanged() {
    if (!this.toggleClass) { return }

    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass)
    })
    this.togglerTargets.forEach(target => {
        target.classList.toggle(this.toggleClass)
      })
      
  }
}
