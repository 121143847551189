import {Controller} from "@hotwired/stimulus"

// in our survey's the user does not always need to press the next button
// if they make a choice, we can submit the form automatically
export default class extends Controller {
    static targets = ["input", "option"]

    select(event) {
        this.inputTarget.value = event.currentTarget.dataset.value
        this.element.requestSubmit()
    }
}
